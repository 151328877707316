var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jee-text-up" },
    [
      _c("a-input", {
        attrs: { required: "required", value: _vm.msg },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event.target.value)
          }
        }
      }),
      _c("label", [_vm._v(_vm._s(_vm.placeholder))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }